<template>
  <div>
    <div class="profile-wrapper profile-wrapper-skeleton" v-if="!profile.id">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>
    <div class="profile-wrapper" v-if="profile.id">
      <profileHedNav :leftNav="false"
                     :isFavorite="profile.is_favorite"
                     @promoFavoriteContactEvent="setFavContact"
                     @promoGoToOwnerMessages="navSelect('messages')"
                     :video_stream_url="profile.video_stream_url"
      ></profileHedNav>
      <div class="video-conf"></div>
      <div class="profile">
        <div class="head-block">
          <div class="avatar round-radius">
            <img :src="setAvatar(profile.photo_url)" alt="image"/>
          </div>
          <div class="head-content">
            <div class="title">
              <span v-if="profile.name">{{ profile.name }} </span>
              <span v-if="profile.surname">{{ profile.surname }}</span>
              <span v-if="!profile.name && !profile.surname"> {{$t('contacts.info.noname')}} </span>
            </div>
            <div class="subtitle"><span v-if="profile.company_name">{{profile.company_name}}</span> <span
                v-if="profile.company_position">{{profile.company_position}}</span></div>
            <div class="info"><span v-if="profile.city">{{profile.city}}</span> <span v-if="profile.country">{{profile.country}}</span>
            </div>
          </div>
        </div>
        <div class="nav">
        <span class="tab cur-p" :class="{'highlight': navSelected === 'profile'}"
              @click="navSelect('profile')">{{$t('title.profile')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'calendar'}"
                @click="navSelect('calendar')">{{$t('title.calendar')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'messages'}"
                @click="navSelect('messages')">{{$t('title.messages')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'notes'}"
                @click="navSelect('notes')">{{$t('title.notes')}}</span>
        </div>
        <div class="content">
          <div :class="{'truncated-text': false}"
               class="description ws-pre-wrap"
               v-if="profile.company_description && navSelected === 'profile'"
          >
            <truncate action-class="read-more"
                      :length="180"
                      :less="$t('button.less')"
                      :clamp="$t('button.more')"
                      :text="profile.company_description"></truncate>
          </div>
        </div>
      </div>

      <!-- Documents -->
      <document-list
          class="field-block field-block-documents"
          :class="{
            'show-action': !showDocuments,
            'show-all': showDocuments
          }"
          :docs="profile.files"
          :showAll="showDocuments"
          @seeAllDocuments="showAllDocuments()"
          v-if="profile.files && profile.files.length && (navSelected === 'profile')"
      ></document-list>


      <!-- Notes -->
      <notes-list
          class="field-block field-block-notes"
          v-if="this.noteList && this.noteList.length && (navSelected === 'profile' || navSelected === 'notes')"
          :notes="this.noteList"
          :showAll="navSelected === 'notes'"
          :showActionButton="navSelected !== 'notes'"
          @seeAllNotes="navSelect('notes')"
      ></notes-list>

      <div v-if="navSelected === 'messages'">
        <div class="field-block">
          <div class="field-header">
            <div class="title">{{$t('title.messages')}}</div>
          </div>
        </div>
        <perfect-scrollbar @ps-y-reach-start="loadMore"
                           class="scroll-height"
                           id="container"
                           ref="msgContainer"
        >
          <div class="field-block" v-if="navSelected === 'messages'">
            <div class="messages" v-for="(message, key) in messageList" :key="key">
              <div class="content-text" :class="{
              'my-message': message.is_mine,
              'their-message': !message.is_mine
            }">
                <div class="date_text">{{ addOffset(message.updated_at).format('lll') }}</div>
                <div class="text_title" v-if="message.contact">
                  <div class="sender-name" v-if="message.is_mine">{{ contact.name }} {{ contact.surname }}</div>
                  <div class="sender-name" v-else>{{ message.contact.name }} {{ message.contact.surname }}</div>
                  <div class="sender-avatar" :style="{ 'background-image' : setMessageSenderAvatar(message) }"></div>
                </div>
                <div class="text ws-pre-wrap">
                  <truncate v-if="message.text" action-class="more-btn"
                            :length="90"
                            :less="$t('button.less')"
                            :clamp="$t('button.more')"
                            :text="message.text"></truncate>
                </div>
              </div>
            </div>
            <div v-if="messagesLoading" class="inner-circular-progress">
              <mu-circular-progress :size="26"></mu-circular-progress>
            </div>
          </div>
          <div v-else class="field-block">
            <div class="company-wrapper company-wrapper-skeleton">
              <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div class="field-block"
           v-if="navSelected === 'calendar'"
      >
        <div class="field-header">
          <div class="field-title">{{$t('title.calendar')}}</div>
        </div>
        <div class="field-content field-content-meetings">
          <contact-calendar
              :userId="user_id"
              :contactData="profile"
              :promoPage="promoPage"
          ></contact-calendar>
        </div>
      </div>

      <div class="field-block" v-if="navSelected === 'messages'">
        <div class="field-header">
          <div class="title">{{$t('title.messagesSend')}}</div>
          <!--        <div class="action">-->
          <!--          <a href="javascript:void(0);" class="btn">{{$t('button.seeAll')}}</a>-->
          <!--        </div>-->
        </div>
        <div class="field-content messages">
          <div class="content-text messages-text">
            <mu-text-field v-model="messageText"
                           class="text-input"
                           full-width
                           @keyup.ctrl.enter="sendMessage()"
                           multi-line
                           :rows="5"
                           :placeholder="$t('title.type_your_message')"></mu-text-field>
            <mu-button class="send-btn"
                       small
                       round
                       @click="sendMessage()">
              {{$t('button.send')}}
            </mu-button>
            <div class="send-btn-keycombo">{{ $t('button.ctrlEnter') }}</div>
          </div>
        </div>
      </div>
<!--      <div class="field-block" v-if="navSelected === 'messages' && !isShowMessageForm">-->
<!--        <div class="field-header">-->
<!--          <div class="title">{{$t('title.msg_will_available_on_event_dates')}}</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import profileHedNav from "./profile-head-nav";
import truncate from 'vue-truncate-collapsed';
import contactCalendar from '../contacts/contactCalendar';
import {DateTimeHelper} from '@/helpers/date-time.helper';
import {FileNameHelper} from "@/helpers/filename.helper";
import DocumentList from "../documents/documentList";
import NotesList from "../notes/notesList";
import Statistics from '@/services/statistics';

const beforeRouteEnter = {to: null, from: null};

export default {
  name: 'promo-profile',
  components: {profileHedNav, truncate, contactCalendar, DocumentList, NotesList},
  computed: {
    ...mapState({
      promoProfile: state => state.promoStore.profile,
      promoPage: state => state.promoStore.promoPage,
      noteListAll: state => state.noteStore.noteList.List,
      // messageListAll: state => state.messageStore.messageList,
      contactList: state => state.contactStore.contactList.List,
      eventInfo: state => state.eventStore.eventInfo,
      messages_count: state => state._badgeNotificationsStore.badgeNotifications.messages_count
    }),
    ...mapGetters('_messageStore', {
      messageListAll: 'userMessages',
      messagesLoading: 'userMessagesIsLoading',
    }),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
    }),
  },
  watch: {
    '$route.params.contact_id': {
      immediate: true,
      deep: true,
      handler() {
        this.event_id = Number(this.$route.params.eventId);
        this.contact_id = Number(this.$route.params.contact_id);
        this.callProfile();
      }
    },
    messages_count: {
      handler() {
        this.setMessages();
      }
    },
    contact_id: {
      immediate: true,
      handler() {
        Statistics.contactView({
          eventId: this.event_id,
          contactId: this.contact_id,
          externalId: this.external_id,
        }, beforeRouteEnter);
      }
    },
    promoProfile: {
      deep: true,
      handler() {
        if (!this.promoProfile)
          return;
        if (this.promoProfile.attached_contacts) {
          this.promoProfile.attached_contacts.forEach(item => {
            if (item.contact.id === this.contact_id) {
              this.profile = item.contact;
              this.user_id = item.contact.user.id;
              this.setNote();
              this.setMessages();
            }
          });
        }
      }
    },
    messageListUser: {
      deep: true,
      handler() {
        this.messageList = this.messageListUser.List;
      }
    },
    setNavSelected: {
      immediate: true,
      deep: true,
      handler() {
        this.navSelect(this.setNavSelected)
      }
    },
    eventInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.enableMessages();
      }
    }
  },
  props: {
    setNavSelected: {
      type: String,
      handler() {
        this.navSelect(this.setNavSelected)
      }
    }
  },
  data() {
    return {
      profile: {},
      noteFav: true,
      leftNav: false,
      event_id: null,
      noteList: {},
      messageList: [],
      navSelected: 'profile',
      messageText: '',
      user_id: null,
      contact_id: null,
      external_id: null,
      userName: '',
      showTeam: false,
      showDocuments: false,
      showNotes: false,
      limit: 20,
      loadMessagesInterval: null,
      isShowMessageForm: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);
  },
  beforeMount() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);

    let payload = {
      event_id: this.event_id,
      contact_id: this.contact_id
    };
    this.$nextTick(() => {
      this.$store.dispatch('promoStore/getProfileByContactId', payload)
    })

  },
  mounted() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);

    this.setNote();
    this.setMessages();
  },
  methods: {
    callProfile() {
      if (!this.event_id && !this.contact_id) {return}
      let payload = {
        event_id: this.event_id,
        contact_id: this.contact_id
      };

      this.$store.dispatch('promoStore/getProfileByContactId', payload)
    },
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    setMessageSenderAvatar(message) {
      // If the message is is_mine, take data from contact entity
      // otherwise from message.contact
      if (message.is_mine && this.contact && this.contact.photo_url) {
        return 'url(' + this.contact.photo_url + ')';
      } else if (message && message.contact && message.contact.photo_url) {
        return 'url(' + message.contact.photo_url + ')';
      }
      // fallback to default avatar
      return 'url(' + require('../../../../assets/images/no-avatar-300x300.png') + ')';
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    setNote() {
      // let data = {
      // 	event_id: this.event_id,
      //
      // };

      Promise.all([
        this.$store.dispatch('noteStore/callNoteListAll',
          {
            event_id: this.event_id,
            user_id: this.user_id
          })
      ]).then(() => {
        this.noteList = this.noteListAll;
      });
    },
    async setMessages() {
      if (!this.event_id || !this.user_id) {return}

      await this.$store.dispatch('_messageStore/callUserMessageList', {
        eventId: this.event_id,
        userId: this.user_id
      })

      if (this.messageListAll && this.messageListAll.List) {
        this.messageList = this.messageListAll.List;
      }

      if (this.messageList && !this.messagesLoading) {
        const container =  await this.$el.querySelector("#container");
        if (container) {
          if (this.limit <= 5) {
            container.scrollTop = 200;
          } else {
            container.scrollTop = container.scrollHeight;
          }
        }
      }
     // this.$store.dispatch('_badgeNotificationsStore/getBadgeNotificationsCount', Number(this.$route.params.eventId));
    },
   async sendMessage() {
      if (this.messageText && this.messageText.trim()) {

        await this.$store.dispatch('_messageStore/sendMessage', {
          eventId: this.event_id,
          userId: this.user_id,
          text: this.messageText.trim()
        })

        await this.setMessages();
        this.messageText = '';
      }
    },
    setAvatar(url) {
      if (url) {
        return url
      } else {
        // return 'src/assets/images/no-avatar-300x300.png'
        return require('../../../../assets/images/no-avatar-300x300.png')
      }
    },
    navSelect(value) {
      this.navSelected = value || 'profile';

      if (this.navSelected === 'messages') {
        if (!this.$refs["msgContainer"]) {return}
        this.$nextTick(() => {
          let msgContainer = this.$refs["msgContainer"].$el;
          if (msgContainer) {
            if (this.limit <= 5) {
              msgContainer.scrollTop = 200;
            } else {
              msgContainer.scrollTop = msgContainer.scrollHeight;
            }
          }
        })
      }

    },
    setFavContact(isFav) {
      let payload = {
        event_id: this.event_id,
        contact_id: this.contact_id
      };
      if (isFav) {
        Promise.all([
          this.$store.dispatch('contactStore/addFavContact', payload)
        ]).then(() => {
          this.$store.dispatch('promoStore/getPromoPage', {event_id: this.event_id, external_id: this.external_id});
        })
      } else {
        Promise.all([
          this.$store.dispatch('contactStore/removeFavContact', payload)
        ]).then(() => {
          this.$store.dispatch('promoStore/getPromoPage', {event_id: this.event_id, external_id: this.external_id});
        })
      }
    },
    showAllTeam() {
      this.showTeam = !this.showTeam;
      // this.$emit("promoShowTeam", this.showTeam);
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments;
      // this.$emit("promoShowDocuments", this.showDocuments);
    },
    showAllNotes() {
      this.showNotes = !this.showNotes;
      // this.$emit("promoShowNotes", this.showNotes);
    },
    toggleNoteFav(id, note) {
      if (!note.is_favorite) {
        this.$store.dispatch('noteStore/removeFav', id)
      } else {
        this.$store.dispatch('noteStore/addFav', id)
      }

    },
    loadMore() {
      if (!this.messagesLoading) {
        if (this.limit <= this.messageListUser.Total) {
          this.limit += 20;
          this.setMessages();
        }
      }
    },
    enableMessages() {
      const dateStart = this.$moment.utc(this.eventInfo.date_start);
      this.isShowMessageForm = this.$moment().isSameOrAfter(dateStart, 'minutes');
    },
  }
};
</script>

<style scoped lang="scss">

  .scroll-height {
    max-height: 350px;
    padding-bottom: 20px;
  }

  .profile-wrapper {
    width: 392px;
    height: auto;
    box-shadow: 5px 5px 15px #3754aa1a;
    border-radius: 25px;
    background-color: $biletum-white;
  }

  .field-block {
    padding: 10px 25px;
    position: relative;
    overflow: hidden;

    .field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 8px;


      .title {
        text-align: left;
        font-family: Point, sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0;
        color: $biletum-black;
        opacity: 0.8;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          left: -300%;
          bottom: 2px;
          content: "";
          border-bottom: 1px solid #eaeaea;
          width: 300%;
        }
      }

      .btn {
        text-align: left;
        font-family: Point, "Point Regular", sans-serif;
        font-size: 13px;
        color: $biletum-light-blue;
      }
    }

    .enumerable {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
      overflow: hidden;
      height: 100px;
    }

    .flex-column-center {
      display: flex;
      justify-content: center;
      flex-flow: column wrap;
      align-items: center;
    }

    .field-content {
      padding: 16px 0;

      &-meetings {
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
      }

      .doc {
        .doc-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 33px;
        }
      }

      .square-radius {
        border-radius: 5px;
      }

      .round-radius {
        border-radius: 50%;
      }

      .avatar {
        width: 60px;
        height: 60px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        margin-top: 6px;
        max-width: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .title,
      .subtitle {
        text-align: center;
        font-family: Point, sans-serif;
        font-weight: 300;
        font-size: 10px;
        color: $biletum-black;
        opacity: 0.8;
      }
    }
  }

  .profile {
    padding: 0 25px;
    margin-bottom: 2rem;

    &::before {
      display: block;
      content: "";
      height: 1px;
      width: 50px;
      background-color: $biletum-light-grey;
      position: relative;
      top: 0;
      left: -50px;
    }

    .head-block {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .square-radius {
        border-radius: 5px;
      }

      .round-radius {
        border-radius: 50%;
      }

      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        opacity: 1;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .head-content {
        padding-left: 26px;
        align-self: flex-start;

        .title {
          text-align: left;
          font-size: 14px;
          font-family: Point, sans-serif;
          font-weight: bold;
          letter-spacing: 0;
          color: $biletum-black;
        }

        .subtitle {
          text-align: left;
          font-family: Point, sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          color: $biletum-black;
        }

        .info {
          text-align: left;
          font-size: 11px;
          font-family: Point, sans-serif;
          letter-spacing: 0;
          color: $biletum-black;
          padding-top: 3px;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      padding: 32px 0 0;

      .tab {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: $biletum-black;
        text-decoration: none;
        opacity: 0.8;
      }

      .highlight {
        border-bottom: 1px solid $biletum-light-blue;
        color: $biletum-light-blue;
      }
    }

    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .description {
        font-family: Point, sans-serif;
        font-size: 12px;
        text-align: left;
      }
    }

  }

  html[lang=ru] .nav a {
    font-size: 12px;
  }

  .content-item {
    margin-bottom: 26px;
    width: 25%;

    &:nth-child(5n+5) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .notes {
    margin-top: 10px;

    &:nth-child(2) {
      margin-top: 0;
    }

    .content-item {

      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .content {
        margin-left: 37px;
      }

      .content .title {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0;
        color: $biletum-black;
      }

      .content .subtitle {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        color: $biletum-black;
      }

    }

    .content-text {
      text-align: left;

      .text_title {
        margin: 14px 0 8px;
        font-family: Point, sans-serif;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
      }

      .text {
        margin: 8px 0;
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }

  .show-all {
    height: auto !important;
  }

  .fav {
    margin-left: auto;
  }

  .profile-wrapper-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .inner-circular-progress {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

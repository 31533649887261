<template>
  <div class="head-nav">
    <biletumSharer :url="shareLink" :position="{t:0,l:0}"
                   ref="biletumSharer"></biletumSharer>
    <div class="btns-group" v-if="leftNav">
      <a href="javascript:void(0);" @click="close()">
        <icon-close-circle-outline/>
      </a>
      <!--      <a href="javascript:void(0);">-->
      <!--        <iconZoomOut/>-->
      <!--      </a>-->
    </div>
    <div class="btns-group">
      <!--<a :href="this.setUrl()">
        <icon-videocam :color="streamColor"/>
      </a>-->
      <a href="javascript:void(0);" @click="goToOwnerMessages()">
        <icon-message/>
      </a>
      <!--      <a href="javascript:void(0);">-->
      <!--        <icon-share/>-->
      <!--      </a>-->
      <a href="javascript:void(0);"
         @click.stop="share()"
         @mouseover="setShareColor('#57C7FF')"
         @mouseleave="setShareColor('#707070')">
        <icon-share :color="shareColor" style="pointer-events: none;"/>
      </a>
      <a href="javascript:void(0);"
         @click="toggleFavorite()">
        <i class="material-icons">
          <icon-star :color="color"/>
        </i>
      </a>
    </div>

  </div>

</template>

<script>
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
// import iconZoomOut from '../../../svg/icons/IconZoomOut';
// import iconVideocam from '../../../svg/icons/Icon-videocam';
import iconMessage from '@/_modules/icons/components/icon-message.vue';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import IconStar from "@/_modules/icons/components/icon-star.vue";
import biletumSharer from "../../popups/biletumSharer"
import UrlHelper from '@/_helpers/url.helper';

export default {
  name: 'profile-head-nav',
  components: {
    IconStar,
    iconCloseCircleOutline,
    // iconZoomOut,
    // iconVideocam,
    iconMessage,
    iconShare,
    biletumSharer
  },
  computed: {},
  watch: {
    leftNav: {
      handler() {
        this.isShow = this.leftNav;
      }
    },
    isFavorite: {
      immediate: true,
      deep: true,
      handler() {
        this.favorite = this.isFavorite;
        this.setFavColor()
      }
    },
    video_stream_url: {
      immediate: true,
      handler() {
        if (this.video_stream_url) {
          this.videoStream = this.video_stream_url;
        }
      }
    }
  },
  props: {
    leftNav: {
      type: Boolean,
      default: false,
      handler() {
        this.isShow = this.leftNav;
      }
    },

    isFavorite: {
      type: Boolean,
      default: false,
      // immediate: true,
      handler() {
        this.favorite = this.isFavorite;
      }
    },
    video_stream_url: {
      default: ''
    }
  },
  data() {
    return {
      isShow: true,
      favorite: false,
      color: '',
      videoStream: '',
      streamColor: '',
      shareColor: '#707070',
      shareLink: '',
    }
  },
  methods: {
    close() {
      this.$emit('closeItem')
    },
    toggleFavorite() {
      this.favorite = !this.favorite;

      this.$emit("promoFavoriteContactEvent", this.favorite);


      this.setFavColor();
    },
    setFavColor() {
      if (this.favorite) {
        this.color = '#00b6f8'
      } else {
        this.color = '#000'
      }
    },
    setUrl() {
      if (this.videoStream) {
        this.streamColor = '#00b6f8';
        return UrlHelper.addMissingUrlSchema(this.videoStream);
      } else {
        this.streamColor = 'rgba(0,0,0,.25)';
        return 'javascript:;'
      }
    },
    goToOwnerMessages() {
      this.$emit('promoGoToOwnerMessages')
    },
    setShareColor(color) {
      this.shareColor = color;
    },
    share() {
      this.shareLink = window.location.protocol + '//' + window.location.host + this.$route.path;
      this.$refs.biletumSharer.toggleSharer();
    }
  }

}

</script>

<style scoped lang="scss">
  .head-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .btns-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      &:last-child {
        margin-left: auto;
      }

      a {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 14px;
          color: $biletum-black;
        }
      }
    }
  }

</style>
